import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react';
import { USER_REQUEST_API } from 'constants/endpoints';
import useApi from 'hooks/useApi';

import Pagination from 'components/pagination/Pagination';
import ErrorDisplay from 'components/errorDisplay/ErrorDisplay';

import { OverlayLoaderComponent } from 'components/loader/Loader';
import SidebarFilter from 'components/sidebarFilter/SidebarFilter';
import { ENTITY_FILTER_TYPES } from 'utils/common';
import { getFilterFromLocalStorage } from 'utils/common';
import { setFilterToLocalStorage } from 'utils/common';
import ManageColumns from 'components/manageColumns/ManageColumns';
import NoDataFound from 'components/noDataFound/NoDataFound';
import { ENTITY_COLUMNS_TYPES } from 'utils/common';
import CustomTable from 'components/table/CustomTable';
import { userRequestColumns } from './variables';
import { formatUsersRequestTableData } from 'utils/common';

const PAGE_SIZE = 50;
const today = new Date();
const tenDaysAgo = new Date();
tenDaysAgo.setDate(today.getDate() - 10);

export default function UserRequests() {
  const { apiCall, loading } = useApi();
  const [userData, setUserData] = useState([]);
  const [apiError, setApiError] = useState(null);
  const lsFiltersUsersPageSize = getFilterFromLocalStorage(
    ENTITY_FILTER_TYPES.REQUEST_PAGE_SIZE
  );
  const [pageSize, setPageSize] = useState(  lsFiltersUsersPageSize && Number.isInteger(lsFiltersUsersPageSize)
  ? lsFiltersUsersPageSize
  : PAGE_SIZE);


  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox, setManageColumnSelectAllCheckbox] = useState(false);

  const fetchData = useCallback(
    async () => {
      const validPageSize = Number.isInteger(pageSize) ? pageSize : PAGE_SIZE;
      try {
        const response = await apiCall.get(`${USER_REQUEST_API}`, {
                  page: currentPage,
                  limit: validPageSize,
                });
  
        const rawData = response?.data?.requests || [];
        const formattedData = formatUsersRequestTableData(rawData); 
  
        setTotalPages(Math.ceil(response?.data?.count / pageSize));
        setTotalCount(response?.data?.count);
        setUserData(formattedData);
      } catch (error) {
        console.error("Error fetching user requests:", error);
        setApiError(error);
      }
    },
    [currentPage, pageSize] 
  );
  

  useEffect(() => {
      fetchData();
  }, [currentPage, pageSize]);

 
  const columns = useMemo(() => userRequestColumns, [userRequestColumns]);
  const data = useMemo(() => formatUsersRequestTableData(userData), [userData]);



  const lsLocalStorageManageColumns = getFilterFromLocalStorage(ENTITY_COLUMNS_TYPES.REQUESTS_COLUMNS);

  const [columnVisibility, setColumnVisibility] = useState(
    lsLocalStorageManageColumns
      ? lsLocalStorageManageColumns
      : columns.reduce((acc, column) => {
          acc[column.accessor] = true;
          return acc;
        }, {})
  );

  const handleColumnChange = (accessor) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [accessor]: !prev[accessor],
    }));
  };

  useEffect(() => {
    setFilterToLocalStorage(
      ENTITY_COLUMNS_TYPES.REQUESTS_COLUMNS,
      columnVisibility
    );
  }, [columnVisibility]);

  const visibleColumns = columns.filter(
    (column) => columnVisibility[column.accessor]
  );



  if (apiError) {
    return <ErrorDisplay error={apiError} />;
  }


  return (
    <>
      <Box height="100vh" bg="#F8FAFB" mr={14} pr={6}>
        <Flex
          justify="space-between"
          align="center"
          p="2"
          boxShadow="sm"
          borderRadius="sm"
          pos="fixed"
          style={{ width: "calc(100vw - 96px)" }}
          zIndex={1000}
        >
          <HStack spacing="4" p={2}>
          
            <ManageColumns
              columns={columns}
              handleColumnChange={handleColumnChange}
              columnVisibility={columnVisibility}
              setManageColumnSelectAllCheckbox={setManageColumnSelectAllCheckbox}
              manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox={manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox}
            />
        
          </HStack>
          <Text
            borderRadius="5px"
            borderWidth="0.5px"
            borderColor="#637381"
            background="#F9F9FB"
            color="#637381"
            p={1}
          >
            Total User Requests: {totalCount}
          </Text>
       
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(value) => setCurrentPage(value)}
            totalCount={totalCount}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            setPageSizeToLocalStorage={(value) =>
              setFilterToLocalStorage(
                ENTITY_FILTER_TYPES.REQUEST_PAGE_SIZE,
                value
              )
            }
          />
        </Flex>
        <SidebarFilter      
        />
        {userData?.length !== 0 ? (
          <>
            <CustomTable
              columns={visibleColumns}
              data={data}
              showSelect={false}
              showAction={false}
            />
          </>
        ) : !loading ? (
          <NoDataFound />
        ) : null}
        <OverlayLoaderComponent isOpen={loading} />
      </Box>    
    </>
  );
}
