import { Flex, Text } from "@chakra-ui/react";
import StatusBadge from "components/badge/StatusBadge";
import { Tooltip } from "@chakra-ui/react";

export const userReportsColumns = [
  {
    Header: 'Name',
    accessor: 'user_data',
    Cell: ({ value }) => (
      <span>
        {value ? `${value.first_name || ''} ${value.last_name || ''}`.trim() : ''}
      </span>
    ),
  },
  {
    Header: 'SLUG',
    accessor: 'ad_data.slug_url',
    Cell: ({ value }) => (
      <a
        _hover={{ textDecoration: 'underline' }}
        href={`${process.env.REACT_APP_HEXAHOME_BASE_URL}/property-details/${value}`}
        target="_blank"
        rel="noreferrer"
        cursor="pointer"
        whiteSpace={'nowrap'}
        style={{ fontSize: '11px', fontWeight: 600, textAlign: 'left' }}
      >
        {value}
      </a>
    ),
  },
  {
    Header: 'Ad Type',
    accessor: 'ad_type',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'Report Type',
    accessor: 'report_type',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ value }) => (
      <Tooltip label={value} hasArrow placement="top">
        <span>{value?.length > 15 ? `${value.substring(0, 15)}...` : value}</span>
      </Tooltip>
    ),
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'Updated At',
    accessor: 'updated_at',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'Phone',
    accessor: 'user_data.phone',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'Role',
    accessor: 'user_data.role',
    Cell: ({ value }) => <span>{value}</span>,
  },
  
];
