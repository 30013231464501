import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  HStack,
  Heading,
  Spinner
} from '@chakra-ui/react';
import CommonForms from 'components/addForm/CommonForm';
import { ALL_PROPERTY_TYPE } from 'constants/dbEnums';
import SelectInput from 'components/addForm/SelectInput';
import { initialState } from 'components/addForm/formInitialState';
import useApi from 'hooks/useApi';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useHistory } from 'react-router-dom';
import { createForm, getFormData, removeKeyValueFromPayload } from './apiService';
import { filterEmptyValues, filterPgData, filterUnusedData, handlePhotoUpload, updateFormDataWithUploads, uploadProfileImage } from './formHandlers';
import SearchInput from 'components/addForm/searchInput';
import toast from 'react-hot-toast';
import { ALL_PROPERTY_TYPE_TAGS } from 'constants/dbEnums';
import formValidation, { customFormkeysArray, customFormKeys } from 'components/addForm/validation';
import { PROPERTY_TYPE_TAGS } from 'constants/dbEnums';
import { PROPERTY_COLLECTIONS } from 'constants/dbEnums';
import { RESIDENTIAL_PROPERTY_TYPE } from 'constants/dbEnums';
import { showToast } from 'components/toast/Toast';
import { COMMERCIAL_PROPERTY_TYPE } from 'constants/dbEnums';
import { useQueryParams } from 'utils/common';
import { SEARCH_FOR } from 'constants/defaultState';
import qs from "qs";
import { getCoverStatus } from 'constants/commonFunctions';
import { SHARING_TYPE } from 'constants/dbEnums';
import { RESTRICTED_PROPERTY_TYPE_OPTIONS } from 'constants/dbEnums';

const App = () => {
  const [propertyType, setPropertyType] = useState('PG');
  const initialFormData = {
    owner_id: "",
    owner_role: "",
    ...(propertyType !== "projects" && {
      type: ALL_PROPERTY_TYPE_TAGS[propertyType],
      property_type: PROPERTY_TYPE_TAGS[propertyType],
    }),
    ...(initialState[ALL_PROPERTY_TYPE_TAGS[propertyType]] || {}), // Common fields
    ...(initialState[propertyType] || {}), // New fields
  };
  

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const { apiCall } = useApi();
  const history = useHistory();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const { propertyId, collectionType, propertyTypeFromQuery, ownerId, companyName } = useQueryParams();

  const handlePropertyChange = (e) => {
    const { value } = e.target;
    setPropertyType(value);
    setFormData((prevData) => {
      const initialData = {
        owner_id: "",
        owner_role: "",
        ...(value !== "projects" && {
          type: ALL_PROPERTY_TYPE_TAGS[value],
          property_type: PROPERTY_TYPE_TAGS[value],
        }),
        ...(initialState[ALL_PROPERTY_TYPE_TAGS[value]] || {}), // Common fields
        ...(initialState[value] || {}),
        ...(prevData._id && {_id:prevData._id}),
      };
    
      // Filter prevData to keep only the keys that exist in initialData
      const filteredPrevData = Object.keys(prevData)
        .filter((key) => key in initialData )
        .reduce((acc, key) => {
          if(key==="property_type" || key==="type"){
            return acc;
          }
          acc[key] = prevData[key];
          return acc;
        }, {});
    
      // Merge filtered prevData with initialData
      const updatedData = { ...initialData, ...filteredPrevData };

      return updatedData;
    });
    
  };

  const handleFormChange = (e) => {
    const { name, value, company_name, owner_role, social_links, profile_pic } = e.target;
    setFormData(prevData => {
      const updatedData = { ...prevData };
      updatedData[name] = value;
      if (social_links) {
        if (!updatedData.social_links) updatedData.social_links = {};
        updatedData.social_links[name] = social_links[name];
      }
      if (profile_pic && (propertyType === SHARING_TYPE.NEED_FLAT || propertyType === SHARING_TYPE.NEED_FLATMATE)) {
        updatedData.user_profile_pic = profile_pic;
      }
      if (name === "owner_id") {
        if (owner_role) {
          updatedData["owner_role"] = owner_role;
        }
        if (company_name) {
          updatedData["company_name"] = company_name;
        }
      }
      return updatedData;
    });
  };

  useEffect(() => {
    const updatedFormData = { ...initialFormData };
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        updatedFormData[key] = formData[key];
      }
    }
    if (ownerId) {
      updatedFormData.owner_id = ownerId;
    }
    setFormData(updatedFormData);
  }, [propertyType]);

  const validateForm = ({ updatedFormData }) => {
    const updatedPayload = removeKeyValueFromPayload(updatedFormData);
    const { furnish_type, furnish_type_items, ...rest } = updatedPayload;
    const payload = {
      ...rest,
      furnish_type,
      ...(furnish_type !== "unfurnished" && {
        furnish_type_items: furnish_type_items,
      }),
    };
    const formType = ALL_PROPERTY_TYPE_TAGS[propertyType];
    let schema;
    if (formData._id) {
      schema = formValidation[formType].update;
    } else {
      schema = formValidation[formType].create;
    }
    if (!schema) {
      console.error(`No validation schema found for form type: ${formType}`);
      toast.error(`No validation schema found for form type: ${formType}`);
      setIsSubmittingForm(false);
      return { newErrors: {}, payload, invalidFields: [] };
    }

    if (schema._inner && schema._inner.keySet) {
      Object.keys(payload).forEach(field => {
        if (!schema._inner.keySet.has(field)) {
          delete payload[field];
        }
      });
    }

    const { error } = schema.validate(payload, { abortEarly: false });
    const newErrors = {};
  
    if (error) {
      error.details.forEach((detail) => {
        const path = detail.path;
        let current = newErrors;
        const key = detail?.context?.key;
        const keysArray = customFormkeysArray[formType];
        const newKey = customFormKeys[formType];
        const message = detail.message.replace(detail.context.label, key);

        if (keysArray?.includes(key)) {
          if (!current[newKey[key]]) {
            current[newKey[key]] = {};
          }
          current[newKey[key]][key] = message;
        }

        if (path.includes('location') || path.includes('address')) {
          if (path.includes('locality')) {
            newErrors["locality"] = (newErrors["locality"] || "") + "Locality is required, ";
          } else if (path.length === 1) {
            current["locality"] = "Locality is not allowed to be empty";
          } else {
            newErrors["locality"] = (newErrors["locality"] || "") + message + ", ";
          }
        }

       
      });
    }

    // Safely remove fields not allowed by the schema
    if (schema._inner && schema._inner.keySet) {
      Object.keys(payload).forEach(field => {
        if (!schema._inner.keySet.has(field)) {
          delete payload[field];
        }
      });
    }

    return { newErrors, payload };
  };

  const handleFormSubmit = async () => {
    try {
      setIsSubmittingForm(true);
      let s3Imgs = [];
      let updatedFormData = {};
      if(formData.photos?.length>0){
        const uploadProjectPhotos = propertyType === ALL_PROPERTY_TYPE_TAGS.projects && formData?.photos?.length>0;
        const uploadedPhotos = await handlePhotoUpload({ apiCall, selectedImages: formData.photos, collectionType: propertyType, formData, uploadProjectPhotos});
        const existingPhotos = formData?.photos?.filter(photo => typeof photo === 'string' && photo.trim() !== '');
        s3Imgs = [...existingPhotos, ...uploadedPhotos];
  
        const coverPhotoIndex = s3Imgs.findIndex((photo) => {
          const isCover = getCoverStatus(photo);
          return isCover;
        });
  
        if (coverPhotoIndex > 0) {
          const [coverPhoto] = s3Imgs.splice(coverPhotoIndex, 1);
          s3Imgs.unshift(coverPhoto);
        }
      }
      if (propertyType === ALL_PROPERTY_TYPE_TAGS.projects) {
        updatedFormData = await updateFormDataWithUploads({ apiCall, propertyType, formData, s3Imgs });
        if (formData?.floor_plan_and_pricing?.length > 0) {
          updatedFormData.floor_plan_and_pricing = filterEmptyValues(updatedFormData?.floor_plan_and_pricing);
        }
      } else {
        filterUnusedData(formData, propertyType);
        updatedFormData = {
          ...formData,
          photos: s3Imgs,
          ...(formData.type === "PG" && {
            pricing: filterPgData(formData.pricing, formData.room_sharing_type),
          }),
          ...(formData.type === "share_flat" && {
            sharing_type: formData.propertyType || formData.property_type,
          }),
        };
      }
      const { newErrors, payload } = validateForm({ updatedFormData });
      if (payload.area?.size && payload.carpet_area?.size) {
        if (payload.carpet_area.size > payload.area.size) {
          if (!newErrors.carpet_area) {
            newErrors.carpet_area = {};
          }
          newErrors.carpet_area.size = "Carpet area size must be less than or equal to area size.";
        }
      }
      if (payload.area?.unit && payload.carpet_area?.unit) {
        if (payload.area.unit !== payload.carpet_area.unit) {
          if (!newErrors.carpet_area) {
            newErrors.carpet_area = {};
          }
          newErrors.carpet_area.unit = "Carpet area unit must be the same as area unit.";
        }
      }
      if (payload.furnish_type !== "unfurnished") {
        if(!payload.furnish_type_items){
          payload.furnish_type_items =[];
        }
        if (payload.furnish_type === "semi_furnished") {
            const semiFurnishedItemsCount = Object.keys(payload.furnish_type_items).length || 0;
            if (semiFurnishedItemsCount < 3) {
                 newErrors.furnish_type_items = "Semi-furnished property need more than 3 Amenities to be selected !!.";
            }
        } else if (payload.furnish_type === "fully_furnished") {
           
            const fullyFurnishedItemsCount = Object.keys(payload.furnish_type_items).length;
            if (fullyFurnishedItemsCount < 5) {
               newErrors.furnish_type_items = "Fully furnished property need more than 5 Amenities to be selected !!.";
            } 
        }
    }
    if(payload.property_type==="need_flat"){
      delete payload.furnish_type;
      delete payload.furnish_type_items;
    }
    if (newErrors.property_condition) {
      if (newErrors.total_cabins_and_total_seats) {
        delete newErrors.total_cabins_and_total_seats.total_cabins;
        delete newErrors.total_cabins_and_total_seats.total_seats;

        // Remove the parent object if it becomes empty
        if (Object.keys(newErrors.total_cabins_and_total_seats).length === 0) {
          delete newErrors.total_cabins_and_total_seats;
        }
      }
    }
      if (Object.keys(newErrors)?.length > 0) {
        const errorMessages = Object.values(newErrors).map(error =>
          typeof error === 'object' ? Object.values(error).join('\n') : error
        ).join('\n');
        setErrors(newErrors);
        console.error('form submit error', newErrors);
        toast.error(`Please correct the following errors:\n${errorMessages}`);
        setIsSubmittingForm(false);
        return;
      }
      const response = await createForm({ apiCall, formData: payload, propertyType });
      if (formData?.user_profile_pic?.length && formData?.owner_id && typeof formData?.user_profile_pic !== "string") {
        uploadProfileImage(apiCall, formData?.user_profile_pic, formData?.owner_id);
      }
      if (response) {
        setIsSubmittingForm(false);
        setFormData(initialFormData);
      }
      setErrors({});
    } catch (error) {
      console.log("Form submit/update error", error);
      setIsSubmittingForm(false);
      showToast({
        message: error.message || "An error occurred while submitting the form.",
        success: false,
        customStyle: {
          background: "#F24052",
          color: "#fff",
          padding: "10px",
        },
      });
    }
  };
  

  const getFormDataFn = async () => {
    try {
      let formType;
      if (collectionType === PROPERTY_COLLECTIONS.PLOT) {
        formType = `${propertyTypeFromQuery}_${collectionType}`;
      } else if (collectionType === PROPERTY_COLLECTIONS.RESIDENTIAL_PROPERTY || collectionType === PROPERTY_COLLECTIONS.COMMERCIAL_PROPERTY || collectionType === PROPERTY_COLLECTIONS.SHARE_FLAT) {
        if (propertyTypeFromQuery === RESIDENTIAL_PROPERTY_TYPE.OTHERS || propertyTypeFromQuery === COMMERCIAL_PROPERTY_TYPE.OTHER) {
          const [baseType] = collectionType.split('_');
          formType = `${baseType}_${propertyTypeFromQuery}`;
        } else {
          formType = propertyTypeFromQuery;
        }
      } else {
        formType = collectionType;
      }
      const response = await getFormData({ apiCall, propertyId, collectionType });
      setPropertyType(formType);
      if (formType === ALL_PROPERTY_TYPE_TAGS.projects) {
        setFormData({ ...response, owner_id: response?.builder_id, company_name: companyName });
      } else {
        setTimeout(() => {
          setFormData({ ...response, type: collectionType, propertyType: formType, property_type: collectionType === PROPERTY_COLLECTIONS.PLOT ? propertyTypeFromQuery :response?.property_type || formType });
        }, 100);
      }
    } catch (error) {
      console.log("Error fetching property data", error);
    }
  };

  useEffect(() => {
    if (propertyId && collectionType) {
      getFormDataFn();
    }
  }, [propertyId, collectionType]);

  useEffect(() => {
    if (propertyTypeFromQuery === ALL_PROPERTY_TYPE_TAGS.projects) {
      setPropertyType(propertyTypeFromQuery);
    }
  }, [propertyTypeFromQuery]);

  return (
    <Box p={4} px={{ base: 4, md: 12 }} maxW={{ base: "100%", md: "1200px" }} mx="auto">
      <Box onClick={() => history.goBack()} style={{ cursor: 'pointer' }}>
        <ArrowBackIcon />
      </Box>
      <Box textAlign="center" mb={6}>
        <Heading>{formData._id?"Update Ads":"Create Ads"}</Heading>
      </Box>
      <HStack spacing={4} mb={6}>
        <SelectInput
          label="Property Type"
          options={ formData._id ? RESTRICTED_PROPERTY_TYPE_OPTIONS[propertyType] || ALL_PROPERTY_TYPE : ALL_PROPERTY_TYPE}
          onChange={handlePropertyChange}
          isRequired={true}
          name="type"
          selectedValue={propertyType}
          error={errors.type}
          disabled={RESTRICTED_PROPERTY_TYPE_OPTIONS[propertyType] ? false : formData?._id || ownerId}
        />
        <SearchInput
          label={propertyType === ALL_PROPERTY_TYPE_TAGS.projects ? "Builder Name" : "User Phone Number or ID"}
          name="owner_id"
          value={(collectionType || ownerId) ? formData?.owner_id : ''}
          onChange={handleFormChange}
          placeholder="Search by Phone Number"
          type="text"
          isRequired={true}
          error={errors.owner_id}
          propertyType={ownerId ? '' : propertyType}
          disabled={formData?._id || ownerId}
          setErrors={setErrors}
          searchFor={propertyType === ALL_PROPERTY_TYPE_TAGS.projects ? SEARCH_FOR.BUILDER : SEARCH_FOR.USER}
        />
      </HStack>
      {formData && (
        <CommonForms
          formType={propertyType}
          formData={formData}
          onChange={handleFormChange}
          errors={errors}
        />
      )}
      <HStack mt={4} justifyContent="center" alignItems="center" spacing={4}>
        {isSubmittingForm && <Spinner size='lg' />}
        <Button
          colorScheme="teal"
          size="lg"
          px={8}
          py={6}
          fontSize="lg"
          bg="purple.500"
          borderRadius="5px"
          borderColor="#637381"
          background={'linear-gradient(180deg, #22ACEE 0%, #0177B4 100%)'}
          color="#ffffff"
          onClick={handleFormSubmit}
          disabled={isSubmittingForm}
        >
          Submit
        </Button>
      </HStack>
    </Box>
  );
};

export default App;
