import { AD_TYPE } from "constants/dbEnums";
import SelectInput from "../SelectInput";
import { AGE_OF_PROPERTY } from "constants/dbEnums";
import { BHK } from "constants/dbEnums";
import { BATHROOMS } from "constants/dbEnums";
import { BALCONIES } from "constants/dbEnums";
import MultiSelect from "../MultiSelect";
import { ADDITIONAL_ROOMS } from "constants/dbEnums";
import { FURNISH_TYPE } from "constants/dbEnums";
import AmenitiesInput from "../AmenitiesInput";
import { INDOOR_AMENITIES } from "constants/dbEnums";
import { FACING } from "constants/dbEnums";
import { FLOORING } from "constants/dbEnums";
import { Box, Flex } from "@chakra-ui/react";
import ValidatedInput from "../ValidatedInput";
import { AREA_UNIT } from "constants/dbEnums";
import { TENANT_TYPE } from "constants/dbEnums";
import CalendarInput from "../CalendarInput";
import CheckboxComponent from "../Checkbox";
import { RENT_TYPE } from "constants/dbEnums";
import { is_price_negotiable } from "constants/dbEnums";
import { is_electricity_and_water_charge } from "constants/dbEnums";
import { LOCK_IN_PERIOD } from "constants/dbEnums";
import { NOTICE_PERIOD } from "constants/dbEnums";
import { LEASE_YEARS } from "constants/dbEnums";
import AutoSuggestComponent from "../location/locality";
import { AMENITIES } from "constants/dbEnums";
import { PREFERENCES } from "constants/dbEnums";
import Image from "../imageUpload/index";
import DescriptionGenerator from "../autoGenerateDescription";
import { CONSTRUCTION_STATUS } from "constants/dbEnums";
import { POSSESSION_PERIOD } from "constants/dbEnums";
import { OWNERSHIP_TAGS } from "constants/dbEnums";
import { IS_HOT_DEAL } from "constants/dbEnums";
import { SECURITY_DEPOSIT_TYPE } from "constants/dbEnums";
import { ALLOW_BROKERS } from "constants/dbEnums";
import { AD_TYPE_TAGS } from "constants/dbEnums";
import { DISCOUNT_TYPE_TAGS } from "constants/dbEnums";
import { DISCOUNT_TYPE } from "constants/dbEnums";
import { FINANCING_AVAILABLE } from "constants/dbEnums";
import { CONSTRUCTION_STATUS_TAGS } from "constants/dbEnums";
import { MONTHS_TAGS } from "constants/dbEnums";
import { tax_and_govt_charges_excluded } from "constants/dbEnums";
import ErrorMessage from "../ErrorMessage";
import { RESIDENTIAL_PROPERTY_TYPE } from "constants/dbEnums";
import { useEffect } from "react";
import { PROPERTY_TYPE_TAGS } from "constants/dbEnums";
import { getDataFromLocalStorage } from "utils/common";
import CitySelectInput from "../location/citySelectInput";
import { LOCATION_TYPE } from "constants/dbEnums";

const AdtypeAndConstructionStatusRender = (props) => {
  const { error, onChange, formData } = props;
  const isOneRkStudio = formData?.property_type === RESIDENTIAL_PROPERTY_TYPE.ONE_RK_STUDIO;
  const handleChange = (e) => {
    const { name, value } = e.target;
    const customEvent = {
      target: {
        name,
        value,
      },
    };
    onChange(customEvent);
  };
  useEffect(() => {
    if (isOneRkStudio && !formData?.ad_type) {
      onChange({
        target: {
          name: 'ad_type',
          value: AD_TYPE_TAGS.RENT,
        },
      });
    }
  }, [isOneRkStudio]);
  return (
    <Flex justify="space-between" mb={4}>
      <Box flex="1" mr={formData?.ad_type === "sell" ? 2 : 0}>
        <SelectInput
          name="ad_type"
          label="Ad type"
          options={AD_TYPE}
          isRequired={true}
          error={error?.ad_type}
          onChange={(e) => handleChange(e)}
          selectedValue={formData?.ad_type}
          disabled={formData?._id}
        />
      </Box>
      {props.formData.ad_type === "sell" && (
        <Box flex="2" ml={2}>
          <CheckboxComponent
            options={CONSTRUCTION_STATUS}
            name="construction_status"
            selectedValues={formData?.construction_status}
            onChange={(e) => handleChange(e)}
            label="Construction Status"
            textSize="lg"
            checkboxSize="lg"
            error={error?.construction_status}
          />
        </Box>
      )}
    </Flex>
  );
}

const residentialForm = {
  ad_type_and_construction_status: AdtypeAndConstructionStatusRender,
  age_of_property_and_possession_period: (props) => {
    const { error, onChange, formData } = props;
    const isUnderCunstruction =
      formData.ad_type === "sell" &&
      formData?.construction_status ===
        CONSTRUCTION_STATUS_TAGS.UNDER_CONSTRUCTION;
    const handleChange = (e, selectedName) => {
      const { name, value } = e.target;
      const updatedValue = {
        ...formData.possession_period,
        [selectedName]: value,
      };
      const customEvent = {
        target: {
          name,
          value: name === "age_of_property" ? value : updatedValue,
        },
      };
      onChange(customEvent);
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={formData.ad_type === "sell" ? 2 : 0}>
          <SelectInput
            name={isUnderCunstruction ? "possession_period" : "age_of_property"}
            label={
              isUnderCunstruction
                ? "Expected Time pf Possession"
                : "Age of Property"
            }
            options={isUnderCunstruction ? POSSESSION_PERIOD : AGE_OF_PROPERTY}
            isRequired={true}
            onChange={(e) => handleChange(e, "period")}
            error={isUnderCunstruction ? "" : error?.age_of_property}
            selectedValue={
              isUnderCunstruction
                ? formData?.possession_period?.period
                : formData?.age_of_property
            }
          />
        </Box>
        {isUnderCunstruction && (
          <Box flex="1" ml={2}>
            <SelectInput
              name="possession_period"
              label="Select Month"
              options={MONTHS_TAGS}
              selectedValue={props.formData.possession_period?.month}
              isRequired={true}
              onChange={(e) => handleChange(e, "month")}
              error={error?.possession_period}
            />
          </Box>
        )}
      </Flex>
    );
  },
  bhk: (props) => {
    const { formData } = props;
    const showBhk =
      formData?.property_type !== RESIDENTIAL_PROPERTY_TYPE.ONE_RK_STUDIO &&
      formData?.property_type !== RESIDENTIAL_PROPERTY_TYPE.OTHERS;
    return (
      <>
        {showBhk && (
          <SelectInput
            name="bhk"
            label="BHK Type"
            options={BHK}
            selectedValue={formData.bhk}
            isRequired={true}
            {...props}
          />
        )}
      </>
    );
  },
  bathrooms: (props) => {
    const { formData } = props;
    const showBathrooms =
      formData?.property_type !== RESIDENTIAL_PROPERTY_TYPE.ONE_RK_STUDIO &&
      formData?.property_type !== RESIDENTIAL_PROPERTY_TYPE.OTHERS;
    return (
      <>
        {showBathrooms && (
          <SelectInput
            name="bathrooms"
            label="No. of Bathrooms"
            options={BATHROOMS}
            selectedValue={props.formData.bathrooms}
            {...props}
          />
        )}
      </>
    );
  },
  balconies: (props) => {
    const { formData } = props;
    const showBalconies =
      formData?.property_type !== RESIDENTIAL_PROPERTY_TYPE.OTHERS;
    return (
      <>
        {showBalconies && (
          <SelectInput
            name="balconies"
            label="No. of Balconies"
            options={BALCONIES}
            selectedValue={props.formData.balconies}
            {...props}
          />
        )}
      </>
    );
  },
  additional_rooms: (props) => (
    <MultiSelect
      name="additional_rooms"
      label="Additional Rooms"
      options={ADDITIONAL_ROOMS}
      displayValue="name"
      value={
        props?.formData?.additional_rooms
          ? props?.formData?.additional_rooms
          : []
      }
      onChange={props.onChange}
    />
  ),
  furnish_type: (props) => {
    const {formData} = props;
    const isOthers = formData?.property_type === PROPERTY_TYPE_TAGS.residential_others;
    const isRent = formData?.ad_type === AD_TYPE_TAGS.RENT;
    const isSell = formData?.ad_type === AD_TYPE_TAGS.SELL;
    return (
      <>
      { (isRent || isSell) &&
        <SelectInput
        name="furnish_type"
        label="Furnish Type"
        options={FURNISH_TYPE}
        propertyType="furnish_type"
        selectedValue={props.value}
        isRequired={true}
        {...props}
      />
      }
      </>
    )
  },
  furnish_type_items: (props) => {
    const { formData, onChange, error } = props;
    if (formData.furnish_type !== "unfurnished" && formData.furnish_type) {
      return (
        <AmenitiesInput
          options={INDOOR_AMENITIES}
          onChange={onChange}
          name="furnish_type_items"
          furnishType={formData?.furnish_type}
          isRequired={true}
          error={error}
          label="Select Amenities"
          selectedValue={
            formData?.furnish_type_items ? formData?.furnish_type_items : {}
          }
        />
      );
    }
  },
  facing: (props) => {
    const { formData } = props;
    const showFacing =
      formData?.property_type !== RESIDENTIAL_PROPERTY_TYPE.OTHERS;
    return (
      <>
        {showFacing && (
          <SelectInput
            name="facing"
            label="Facing"
            options={FACING}
            selectedValue={props.value}
            isRequired={
              props.formData.ad_type === "sell" &&
              props.formData.propertyType !== "others" &&
              true
            }
            {...props}
          />
        )}
      </>
    );
  },
  flooring_type_and_ownership: (props) => {
    const { error, onChange, formData } = props;
    const showFlooring =
      formData?.property_type !== RESIDENTIAL_PROPERTY_TYPE.OTHERS;
    const handleChange = (e) => {
      const { name, value } = e.target;
      const customEvent = {
        target: {
          name,
          value,
        },
      };
      onChange(customEvent);
    };
    return (
      <>
        {showFlooring && (
          <Flex justify="space-between" mb={4}>
            <Box flex="1" mr={formData.ad_type === "sell" ? 2 : 0}>
              <SelectInput
                name="flooring_type"
                label="Flooring Type"
                options={FLOORING}
                onChange={handleChange}
                error={error?.flooring_type}
                selectedValue={formData?.flooring_type}
              />
            </Box>
            {formData.ad_type === "sell" && (
              <Box flex="1" ml={2}>
                <SelectInput
                  name="ownership"
                  label="Ownership"
                  options={OWNERSHIP_TAGS}
                  onChange={handleChange}
                  isRequired={true}
                  error={error?.ownership}
                  selectedValue={formData?.ownership}
                />
              </Box>
            )}
          </Flex>
        )}
      </>
    );
  },
  area: (props) => {
    const { onChange, formData, name: selectedName, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      const updatedValue = {
        ...formData.area,
        [name]: value,
      };
      onChange({
        target: {
          name: selectedName,
          value: updatedValue,
        },
      });
    };
    return (
      <Flex>
        <div style={{ flex: 3 }}>
          <ValidatedInput
            name="size"
            placeholder="Enter Build up area"
            type="number"
            onChange={(e) => handleChange(e)}
            label="Build Up Area"
            isRequired={true}
            valueInNumber={true}
            error={error?.size}
            value={formData?.area?.size ? formData?.area?.size : ""}
          />
        </div>
        <div style={{ flex: 2 }}>
          <SelectInput
            name="unit"
            label="Unit"
            options={AREA_UNIT}
            selectedValue={formData?.area?.unit}
            onChange={(e) => handleChange(e)}
            error={error?.unit}
            isRequired={true}
          />
        </div>
      </Flex>
    );
  },
  carpet_area: (props) => {
    const { onChange, formData, name: selectedName, value } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      const updatedValue = {
        ...formData.carpet_area,
        [name]: value,
      };
      onChange({
        target: {
          name: selectedName,
          value: updatedValue,
        },
      });
    };
    return (
      <Flex>
        <div style={{ flex: 3 }}>
          <ValidatedInput
            name="size"
            placeholder="Enter carpet area"
            type="number"
            label="Carpet Area"
            valueInNumber={true}
            onChange={(e) => handleChange(e)}
            value={formData?.carpet_area?.size}
          />
        </div>
        <div style={{ flex: 2 }}>
          <SelectInput
            name="unit"
            label="Unit"
            options={AREA_UNIT}
            selectedValue={formData?.carpet_area?.unit}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </Flex>
    );
  },
  covered_parking: (props) => {
    const { formData } = props;
    const showCoveredParking =
      formData?.property_type !== RESIDENTIAL_PROPERTY_TYPE.OTHERS;
    return (
      <>
        {showCoveredParking && (
          <ValidatedInput
            name="covered_parking"
            value={props.value}
            placeholder="No. of Covered Parking"
            type="number"
            onChange={props.onChange}
            label="Covered Parking"
            valueInNumber={true}
            error={props.error}
          />
        )}
      </>
    );
  },
  open_parking: (props) => {
    const { formData } = props;
    const showOpenParking =
      formData?.property_type !== RESIDENTIAL_PROPERTY_TYPE.OTHERS;
    return (
      <> 
        { showOpenParking &&
          <ValidatedInput
            name="open_parking"
            value={props.value}
            placeholder="No. of Open Parking"
            type="number"
            onChange={props.onChange}
            label="Open Parking"
            valueInNumber={true}
            error={props.error}
          />
        }
      </>
    );
  },
  total_floors_and_your_floor: (props) => {
    const { onChange, formData, error } = props;
    const showYourFloor =
      formData?.property_type !== RESIDENTIAL_PROPERTY_TYPE.INDEPENDENT_HOUSE &&
      formData?.property_type !== RESIDENTIAL_PROPERTY_TYPE.VILA_FARM_HOUSE;
    const handleChange = (e) => {
      const { name, value } = e.target;
      const customEvent = {
        target: {
          name,
          value,
        },
      };
      onChange(customEvent);
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={showYourFloor ? 2 : 0}>
          <ValidatedInput
            name="total_floors"
            label="Total Floors"
            value={formData?.total_floors}
            placeholder="Enter no. of Floors"
            type="number"
            isRequired={true}
            valueInNumber={true}
            error={error?.total_floors}
            onChange={(e) => handleChange(e)}
          />
        </Box>
        {showYourFloor && (
          <Box flex="1" ml={2}>
            <ValidatedInput
              name="your_floor"
              label="Your Floor"
              value={formData?.your_floor}
              placeholder="Enter no. of your floor"
              type="number"
              isRequired={true}
              valueInNumber={true}
              error={error?.your_floor}
              isDisabled={formData?.total_floors <= 0}
              onChange={(e) => handleChange(e)}
            />
          </Box>
        )}
      </Flex>
    );
  },
  preferred_tenant: (props) => {
    const { formData } = props;
    return (
      <>
        {formData.ad_type === "rent" && (
          <MultiSelect
            name="preferred_tenant"
            label="Preferred Tenant"
            options={TENANT_TYPE}
            displayValue="name"
            value={props.value}
            onChange={props.onChange}
            isRequired={true}
            error={props.error}
          />
        )}
      </>
    );
  },
  available_from_and_allow_brokers: (props) => {
    const { onChange, formData, error } = props;
    const handlChange = (e) => {
      const { name, checked, value } = e.target;
      onChange({
        target: {
          name: name,
          value: name === "allow_brokers" ? checked : value,
        },
      });
    };
    return (
      <>
        <Flex justify="space-between" mb={4}>
          <Box flex="2" ml={2}>
            <CalendarInput
              name="available_from"
              label="Available From"
              value={formData?.available_from}
              isRequired={true}
              onChange={(e) => handlChange(e)}
              error={error?.available_from}
            />
          </Box>
          <Box flex="2" ml={2}>
            <CheckboxComponent
              options={ALLOW_BROKERS}
              name="allow_brokers"
              onChange={(e) => handlChange(e)}
              label="Allow Brokers"
              textSize="lg"
              checkboxSize="lg"
              {...(formData?.allow_brokers && { selectedValues: "true" })}
            />
          </Box>
        </Flex>
        {/* )} */}
      </>
    );
  },
  is_on_lease_and_is_hot_deal_and_allow_brokers: (props) => {
    const { onChange, formData } = props;
    const isRent = formData?.ad_type === "rent" ? true : false;
    const handlChange = (e) => {
      const { name, checked, value } = e.target;
      const updatedValue = value === "only_lease" ? true : false;
      onChange({
        target: {
          name: name,
          value: name === "is_hot_deal" ? checked : updatedValue,
        },
      });
    };
    return (
      <Flex>
        <Box ml={2}>
          <CheckboxComponent
            options={isRent ? RENT_TYPE : IS_HOT_DEAL}
            name={isRent ? "is_on_lease" : "is_hot_deal"}
            onChange={(e) => handlChange(e)}
            label={isRent ? "Rent Type" : "Hot Deal"}
            textSize="lg"
            checkboxSize="lg"
            isRequired={isRent ? true : false}
            {...(isRent
              ? formData?.is_on_lease !== undefined && {
                  selectedValues: formData.is_on_lease
                    ? "only_lease"
                    : "only_rent",
                }
              : formData?.is_hot_deal && { selectedValues: "true" })}
          />
        </Box>
      </Flex>
    );
  },
  discount: (props) => {
    const { onChange, formData, name: selectedName, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      const updatedValue = {
        ...formData.discount,
        [name]: value,
      };
      onChange({
        target: {
          name: selectedName,
          value: updatedValue,
        },
      });
    };
    return (
      <>
        {formData.ad_type === AD_TYPE_TAGS.SELL && formData.is_hot_deal && (
          <>
            <Flex>
              <div style={{ flex: 3 }}>
                <ValidatedInput
                  name="amount"
                  placeholder={
                    formData?.discount?.type === DISCOUNT_TYPE.PERCENTAGE
                      ? "Enter Percentage"
                      : "Enter Amount"
                  }
                  type="number"
                  onChange={(e) => handleChange(e)}
                  label="Please Specify the type of the discount"
                  valueInNumber={true}
                  isRequired={true}
                  error={error?.amount}
                  value={formData?.discount?.amount}
                />
              </div>
              <div style={{ flex: 2 }}>
                <SelectInput
                  name="type"
                  label="Type"
                  options={DISCOUNT_TYPE_TAGS}
                  onChange={(e) => handleChange(e)}
                  isRequired={true}
                  error={error?.type}
                  selectedValue={formData?.discount?.type}
                />
              </div>
            </Flex>
            {typeof error === "string" && <ErrorMessage error={error} />}
          </>
        )}
      </>
    );
  },
  discount_validity_and_financing_available: (props) => {
    const { formData, onChange, error } = props;
    const handleChange = (e) => {
      const { name, checked, value } = e.target;
      onChange({
        target: {
          name: name,
          value: name === "financing_available" ? checked : value,
        },
      });
    };
    return (
      <>
        {formData?.ad_type === AD_TYPE_TAGS.SELL && formData.is_hot_deal && (
          <Flex justify="space-between" mb={4}>
            <Box flex="2" ml={2}>
              <CalendarInput
                name="discount_validity"
                label="Discount Validity"
                value={formData?.discount_validity}
                onChange={(e) => handleChange(e)}
                isRequired={true}
                error={error?.discount_validity}
              />
            </Box>
            <Box flex="2" ml={2}>
              <CheckboxComponent
                options={FINANCING_AVAILABLE}
                name="financing_available"
                onChange={(e) => handleChange(e)}
                label="Financing Available"
                textSize="lg"
                checkboxSize="lg"
                isRequired={true}
                error={error?.financing_available}
                {...(formData?.financing_available && {
                  selectedValues: "true",
                })}
              />
            </Box>
          </Flex>
        )}
      </>
    );
  },
  approx_price: (props) => {
    const { onChange, formData, error } = props;
    const label =
      formData?.ad_type === "rent"
        ? formData?.is_on_lease === true
          ? "Lease Amount (Rs)"
          : "Rent Amount (Rs)"
        : "Expected Price (All inclusive price)";
    const placeholder =
      formData?.ad_type === "rent"
        ? formData?.is_on_lease === true
          ? "Enter lease amount"
          : "Enter rent amount"
        : "Enter expected price";
    return (
      <ValidatedInput
        name="approx_price"
        label={label}
        value={props.value}
        placeholder={placeholder}
        type="number"
        isRequired={true}
        valueInNumber={true}
        error={error}
        onChange={onChange}
      />
    );
  },
  maintenance_charges_and_annual_dues_payable: (props) => {
    const { formData, onChange } = props;
    const isRent = formData.ad_type === AD_TYPE_TAGS.RENT;
    return (
      <ValidatedInput
        name={isRent ? "maintenance_charges" : "annual_dues_payable"}
        placeholder={isRent ? "Enter maintenance charges" : "Enter annual dues"}
        type="number"
        onChange={onChange}
        label={
          isRent
            ? "Maintenance Charges (per month)"
            : "Annual dues payable (Rs)"
        }
        valueInNumber={true}
        error={props.error}
        value={
          isRent
            ? formData?.maintenance_charges
            : formData?.annual_dues_payable
            ? formData?.annual_dues_payable
            : ""
        }
      />
    );
  },
  booking_amount: (props) => (
    <ValidatedInput
      name="booking_amount"
      value={
        props?.formData?.booking_amount ? props?.formData?.booking_amount : ""
      }
      placeholder="Enter booking amount"
      type="number"
      onChange={props.onChange}
      label="Booking Amount (Rs)"
      valueInNumber={true}
      error={props.error}
    />
  ),
  other_charges: (props) => (
    <ValidatedInput
      name="other_charges"
      value={props.formData?.other_charges ? props.formData?.other_charges : ""}
      placeholder="Enter other charges"
      type="number"
      onChange={props.onChange}
      label="Other Charges (Rs)"
      valueInNumber={true}
    />
  ),
  negotiable: (props) => {
    const { formData, onChange } = props;
    const handleChange = (e) => {
      const { name, checked } = e.target;
      onChange({
        target: {
          name,
          value: checked,
        },
      });
    };
    return (
      <Flex gap={4}>
        <div style={{ flex: 2 }}>
          <CheckboxComponent
            options={is_price_negotiable}
            name="negotiable"
            onChange={(e) => handleChange(e)}
            label="Is price negotiable"
            textSize="lg"
            checkboxSize="lg"
            {...(formData?.negotiable && { selectedValues: "true" })}
          />
        </div>
        {formData?.ad_type === "rent" && (
          <div style={{ flex: 3 }}>
            <CheckboxComponent
              options={is_electricity_and_water_charge}
              name="is_electricity_and_water_charge"
              onChange={(e) => handleChange(e)}
              label="Is electricity charges included"
              textSize="lg"
              checkboxSize="lg"
              {...(formData?.is_electricity_and_water_charge && {
                selectedValues: "true",
              })}
            />
          </div>
        )}
        {formData.ad_type === "sell" && (
          <div style={{ flex: 3 }}>
            <CheckboxComponent
              options={tax_and_govt_charges_excluded}
              name="tax_and_govt_charges_excluded"
              onChange={(e) => handleChange(e)}
              label="Tax and govt charges"
              textSize="lg"
              checkboxSize="lg"
              {...(formData?.tax_and_govt_charges_excluded && {
                selectedValues: "true",
              })}
            />
          </div>
        )}
      </Flex>
    );
  },
  security_deposit: (props) => {
    const { formData, onChange, error } = props;
    const handleChange = (e) => {
      const { name, value: updatedValue } = e.target;
      const updatedvalue = {
        ...formData.security_deposit,
        ...(name === "type" && { type: updatedValue }),
        ...(name === "amount" && { amount: Number(updatedValue) }),
        ...(name === "month" && {
          amount: formData.approx_price * Number(updatedValue),
        }),
      };
      onChange({
        target: {
          name: "security_deposit",
          value: updatedvalue,
        },
      });
    };
    return (
      <>
        {formData.ad_type === AD_TYPE_TAGS.RENT && !formData.is_on_lease && (
          <Flex direction="row" alignItems="center" gap={1}>
            <div style={{ flex: 3 }}>
              <CheckboxComponent
                options={SECURITY_DEPOSIT_TYPE}
                name="type"
                onChange={(e) => handleChange(e)}
                label="Security Deposit"
                selectedValues={formData?.security_deposit?.type ?? ""}
              />
            </div>
            <div style={{ flex: 2 }}>
              {formData?.security_deposit?.type &&
                formData?.security_deposit?.type !== "none" && (
                  <ValidatedInput
                    name={
                      formData?.security_deposit?.type === "fixed"
                        ? "amount"
                        : "month"
                    }
                    placeholder={
                      formData?.security_deposit?.type === "fixed"
                        ? "Enter the amount in rs"
                        : "Enter no. of months (max 36)"
                    }
                    type="number"
                    onChange={(e) => handleChange(e)}
                    label={
                      formData?.security_deposit?.type === "fixed"
                        ? "Amount in RS"
                        : "Months (max 36)"
                    }
                    valueInNumber={true}
                    error={error?.security_deposit}
                    isRequired={true}
                    value={
                      formData?.security_deposit?.type === "fixed"
                        ? formData?.security_deposit?.amount
                        : formData?.security_deposit?.month
                    }
                  />
                )}
            </div>
          </Flex>
        )}
      </>
    );
  },
  notice_period: (props) => {
    const { onChange, formData } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value: Number(value),
        },
      });
    };
    return (
      <>
        {formData?.ad_type === "rent" && !formData.is_on_lease && (
          <SelectInput
            name="notice_period"
            label="Notice Period (In months)"
            options={NOTICE_PERIOD}
            propertyType="notice_period"
            selectedValue={props.value}
            onChange={(e) => handleChange(e)}
          />
        )}
      </>
    );
  },
  lock_in_period_and_lease_years: (props) => {
    const { formData, onChange, value } = props;
    const handleChange = (e) => {
      const { name, value: updatedValue } = e.target;
      const updatedData = {
        ...formData.lock_in_period,
        [name]: updatedValue,
      };
      onChange({
        target: {
          name: name === "lease_years" ? name : "lock_in_period",
          value: name === "lease_years" ? updatedValue : updatedData,
        },
      });
    };
    return (
      <>
        {formData.ad_type === AD_TYPE_TAGS.RENT &&
          (formData.is_on_lease === true ? (
            <SelectInput
              name="lease_years"
              label="No. of years to Lease"
              options={LEASE_YEARS}
              selectedValue={formData?.lease_years}
              onChange={(e) => handleChange(e)}
              isRequired={true}
            />
          ) : (
            <Flex gap={4}>
              <div>
                <SelectInput
                  name="type"
                  label="Lock in Period"
                  options={LOCK_IN_PERIOD}
                  propertyType="lock_in_period"
                  selectedValue={formData?.lock_in_period?.type}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              {formData?.lock_in_period?.type === "custom" && (
                <div>
                  <ValidatedInput
                    name="period"
                    value={formData?.lock_in_period?.period}
                    placeholder="Enter no. of months"
                    type="number"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    label="Months (max 30)"
                    isRequired={true}
                    valueInNumber={true}
                  />
                </div>
              )}
            </Flex>
          ))}
      </>
    );
  },
  location: (props) => {
    const { onChange, error, formData } = props;
    const selectedValue = formData?.location?.city;
    const handleChange = (e) => {
      const { value: selectedValue } = e.target;
      const updatedValue = {
        city: selectedValue.name
      };
      onChange({
        target: {
          name: "location",
          value: updatedValue,
        },
      });
    };
    return (
      <>
        <CitySelectInput
          name="city"
          label="Location"
          onChange={(e) => handleChange(e)}
          isRequired={true}
          error={error?.city}
          selectedValue={selectedValue}
          result_type={LOCATION_TYPE.CITY}
        />
      </>
    );
  },
  locality: (props) => {
    const { onChange, formData, error } = props;
    const topCities = getDataFromLocalStorage('topCities');
    const selectedValue = topCities?.find(
      (city) => city.name === formData?.location?.city
    );
    const handleChange = (e) => {
      const { name, value: selectedValue } = e.target;
      if (name === "locality") {
        const data = JSON.parse(selectedValue);
        onChange({
          target: {
            name: "location",
            value: data,
          },
        });
      } else {
        onChange({
          target: {
            name: "location",
            value: {
              ...formData.location,
              [name]: selectedValue,
            },
          },
        });
      }
    };
    return (
      <>
        <Flex gap={4}>
          <div stle={{ flex: 2 }}>
            <AutoSuggestComponent
              name="locality"
              selectedValue={formData?.location?.label}
              placeholder="Search by locality, area"
              onChange={(e) => handleChange(e)}
              label="Locality (Enter area or locality address)"
              isRequired={true}
              selectedCity={formData?.location?.city ? formData.location?.city : selectedValue?.city}
              error={error}
              location={formData.location}
              result_type={LOCATION_TYPE.LOCALITY}
            />
          </div>
          <div style={{ flex: 2 }}>
            <ValidatedInput
              name="building"
              placeholder="Enter society or area"
              type="text"
              onChange={(e) => handleChange(e)}
              label="Building (Enter appartment or area)"
              value={formData?.location?.building}
            />
          </div>
        </Flex>
      </>
    );
  },
  amenities: (props) => (
    <MultiSelect
      name="amenities"
      label="Select Amenities"
      options={AMENITIES}
      displayValue="name"
      value={props.value}
      onChange={props.onChange}
    />
  ),
  preferences: (props) => {
    const { formData } = props;
    return (
      <>
        {formData.ad_type === "rent" && (
          <MultiSelect
            name="preferences"
            label="Select Preferences"
            options={PREFERENCES}
            displayValue="name"
            value={props.value}
            onChange={props.onChange}
          />
        )}
      </>
    );
  },
  photos: (props) => {
    const { formData, onChange, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value: value,
        },
      });
    };
    return (
      <>
        <Image
          onChange={(e) => handleChange(e)}
          name="photos"
          collectionType={formData.type}
          error={error}
          value={formData?._id ? formData?.photos : []}
        />
      </>
    );
  },
  description: (props) => {
    const { value, onChange, formData, error } = props;
    return (
      <DescriptionGenerator
        onChange={onChange}
        formData={formData}
        value={value}
        name="description"
        isRequired={true}
        label="Description"
        placeholder="Enter description or generate by AI"
        error={error}
      />
    );
  },
  slug_url: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({
        target: {
          name,
          value,
        },
      });
    };
    return (
      <>
        {formData?._id && (
          <ValidatedInput
            name="slug_url"
            label="Slug Url"
            placeholder="Add Slug Url"
            type="text"
            isRequired={true}
            onChange={(e) => handleChange(e)}
            value={formData.slug_url}
            error={error}
          />
        )}
      </>
    );
  },
  meta_title: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({ target: { name, value } });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="meta_title"
            label="Meta Title"
            placeholder="Project name"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error}
            value={formData?.meta_title}
            styles={{ height: "60px" }}
          />
        </Box>
      </Flex>
    );
  },
  meta_description: (props) => {
    const { onChange, formData, error } = props;
    const handleChange = (e) => {
      const { name, value } = e.target;
      onChange({ target: { name, value } });
    };
    return (
      <Flex justify="space-between" mb={4}>
        <Box flex="1" mr={2}>
          <ValidatedInput
            name="meta_description"
            label="Meta Description"
            placeholder="Enter about"
            type="text"
            onChange={(e) => handleChange(e)}
            error={error}
            value={formData?.meta_description}
            styles={{ height: "60px" }}
          />
        </Box>
      </Flex>
    );
  },
};

export default residentialForm;
