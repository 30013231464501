import { Flex, Text } from "@chakra-ui/react";
import StatusBadge from "components/badge/StatusBadge";
import { Tooltip } from "@chakra-ui/react";

export const userFeedbackColumns = [
  {
    Header: 'Name',
    accessor: 'user_data',
    Cell: ({ value }) => (
      <span>
        {value ? `${value.first_name || ''} ${value.last_name || ''}`.trim() : ''}
      </span>
    ),
  },
  {
    Header: 'Feedback Type',
    accessor: 'feedback_type',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'Feedback',
    accessor: 'feedback',
    Cell: ({ value }) => (
      <Tooltip label={value} hasArrow placement="top">
        <span>{value?.length > 15 ? `${value.substring(0, 15)}...` : value}</span>
      </Tooltip>
    ),
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'Phone',
    accessor: 'user_data.phone',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'Email',
    accessor: 'user_data.email',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'Role',
    accessor: 'user_data.role',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'User ID',
    accessor: 'user_id',
    Cell: ({ value }) => <span>{value}</span>,
  },
];



