import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  Text
} from '@chakra-ui/react';
import { USER_FEEDBACK_API } from 'constants/endpoints';
import useApi from 'hooks/useApi';
import { userFeedbackColumns } from './variables';
import Pagination from 'components/pagination/Pagination';
import ErrorDisplay from 'components/errorDisplay/ErrorDisplay';
import { OverlayLoaderComponent } from 'components/loader/Loader';
import SidebarFilter from 'components/sidebarFilter/SidebarFilter';
import { ENTITY_FILTER_TYPES, getFilterFromLocalStorage, setFilterToLocalStorage } from 'utils/common';
import ManageColumns from 'components/manageColumns/ManageColumns';
import NoDataFound from 'components/noDataFound/NoDataFound';
import { ENTITY_COLUMNS_TYPES } from 'utils/common';
import CustomTable from 'components/table/CustomTable';
import { formatUsersFeedbackTableData } from 'utils/common';

const PAGE_SIZE = 50;

const excludedColumnVisiblity = [
  'slug_url',
  'username'
];

export default function UserFeedbacks() {
  const { apiCall, loading } = useApi();
  const [userData, setUserData] = useState([]);
  const [apiError, setApiError] = useState(null);
  const lsFiltersUsersPageSize = getFilterFromLocalStorage(ENTITY_FILTER_TYPES.USERS_PAGE_SIZE);
  const [pageSize, setPageSize] = useState(  lsFiltersUsersPageSize && Number.isInteger(lsFiltersUsersPageSize)
  ? lsFiltersUsersPageSize
  : PAGE_SIZE);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [manageColumnSelectAllCheckbox, setManageColumnSelectAllCheckbox] = useState(false);

  const fetchData = useCallback(async () => {
    const validPageSize = Number.isInteger(pageSize) ? pageSize : PAGE_SIZE;
    try {
      const response = await apiCall.get(`${USER_FEEDBACK_API}`, {
        page: currentPage,
        limit: validPageSize,
      });

      const rawData = response?.data?.feedbacks || [];
      const formattedData = formatUsersFeedbackTableData(rawData);

      setTotalPages(Math.ceil(response?.data?.count / pageSize));
      setTotalCount(response?.data?.count);
      setUserData(formattedData);
    } catch (error) {
      console.error("Error fetching user feedbacks:", error);
      setApiError(error);
    }
  }, [currentPage,pageSize]);

  useEffect(() => {
      fetchData(); 
  }, [currentPage, pageSize, fetchData]);


  const columns = useMemo(() => userFeedbackColumns, []);
  const data = useMemo(() => formatUsersFeedbackTableData(userData), [userData]);
  


  const lsLocalStorageManageColumns = getFilterFromLocalStorage(ENTITY_COLUMNS_TYPES.FEEDBACKS_COLUMNS);

  const [columnVisibility, setColumnVisibility] = useState(
    lsLocalStorageManageColumns
      ? lsLocalStorageManageColumns
      : columns.reduce((acc, column) => {
          acc[column.accessor] = true;
          return acc;
        }, {})
  );

  const handleColumnChange = (accessor) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [accessor]: !prev[accessor],
    }));
  };

  useEffect(() => {
    setFilterToLocalStorage(ENTITY_COLUMNS_TYPES.FEEDBACKS_COLUMNS, columnVisibility);
  }, [columnVisibility]);

  const visibleColumns = columns.filter((column) => columnVisibility[column.accessor]);

  if (apiError) {
    return <ErrorDisplay error={apiError} />;
  }

  return (
    <Box height="100vh" bg="#F8FAFB">
      <Flex
        justify="space-between"
        align="center"
        p="2"
        boxShadow="sm"
        borderRadius="sm"
        pos="fixed"
        style={{ width: "calc(100vw - 96px)" }}
        zIndex={1000}
      >
        <HStack spacing="4" p={2}>
          <ManageColumns
            columns={columns}
            handleColumnChange={handleColumnChange}
            columnVisibility={columnVisibility}
            setManageColumnSelectAllCheckbox={setManageColumnSelectAllCheckbox}
            manageColumnSelectḀ̥̥̥̥̥̥̥̥llCheckbox={manageColumnSelectAllCheckbox}
          />
        </HStack>
        <Text
          borderRadius="5px"
          borderWidth="0.5px"
          borderColor="#637381"
          background="#F9F9FB"
          color="#637381"
          p={1}
        >
          Total User Feedbacks: {totalCount}
        </Text>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={(value) => setCurrentPage(value)}
          totalCount={totalCount}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setPageSizeToLocalStorage={(value) =>
            setFilterToLocalStorage(ENTITY_FILTER_TYPES.USERS_PAGE_SIZE, value)
          }
        />
      </Flex>
      <SidebarFilter
      />
      {userData?.length !== 0 ? (
        <CustomTable
          columns={visibleColumns}
          data={data}    
          showSelect = {false}
          showAction= {false}
        />
      ) : !loading ? (
        <NoDataFound />
      ) : null}
      <OverlayLoaderComponent isOpen={loading} />
    </Box>
  );
}
