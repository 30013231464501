import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  TableContainer,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
  InputGroup,
} from "@chakra-ui/react";
import { USER_ACTIVITY } from "constants/endpoints";
import useApi from "hooks/useApi";
import { OverlayLoaderComponent } from "components/loader/Loader";
import ErrorDisplay from "components/errorDisplay/ErrorDisplay";
import { formatDateToDDMMYYYY } from "utils/common";
import UserActivityTable from "components/table/UserActivityTable";
import SearchesColumns from "./variables/searches.js";
import LeadColumns from "./variables/leads.js";
import { FiChevronsDown, FiFilter } from "react-icons/fi";
import SidebarFilter from "components/sidebarFilter/SidebarFilter.jsx";
import { USER_ACTIVITY_ROLE_DATA } from "constants/filterDropdownData.js";
import CommonModal from "components/modal/CommonModal.jsx";
import { UPDATE_ENTITY_PATH } from "constants/endpoints.js";
import UserRequirementModal from "../users/components/modal/UserRequirementModal.js";
import { formatSearchResult } from "constants/commonFunctions.js";
import { PROPERTY_TYPE_OPTIONS } from "constants/defaultState.js";
import { actions } from "./variables/actions.js";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min.js";
import { SearchBar } from "components/navbar/searchBar/SearchBar.js";
import { ENTITY_FILTER_TYPES } from "utils/common.js";
import { getFilterFromLocalStorage, setFilterToLocalStorage } from "utils/common.js";
import Pagination from "components/pagination/Pagination.js";

const PAGE_SIZE = 50;

export default function UserActivity() {
  const lsFilters = getFilterFromLocalStorage(ENTITY_FILTER_TYPES.ACTIVITY_FILTER);
  const lsFiltersUsersPageSize = getFilterFromLocalStorage(
    ENTITY_FILTER_TYPES.ACTIVITY_PAGE_SIZE
  );
  const [pageSize, setPageSize] = useState(
    lsFiltersUsersPageSize ? lsFiltersUsersPageSize : PAGE_SIZE
  );
  const [searchText, setSearchText] = useState('');
  const today = new Date();
  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(today.getDate() - 10);
  const initialFilterData = {
    role: [],
    showImportant: false,
    startDate: "",
    endDate: "",
  };

  const [userActivity, setUserActivity] = useState({
    searches: [],
    leads: [],
    totlatSearches: 0,
    totlatLeads: 0,
  });
  const [startDate, setStartDate] = useState(lsFilters?.startDate || "");
  const [endDate, setEndDate] = useState(lsFilters?.endDate || "");
  const [isOpenFilterSidebar, setIsOpenFilterSidebar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedData, setUpdatedData] = useState({ data: [], type: "" });
  const [openRequirementForm, setOpenRequirementForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [requirementData, setRequirementData] = useState(false);
  const { search } = useLocation();
  const queryObject = Object.fromEntries(new URLSearchParams(search));
  const { apiCall, error } = useApi();
  const [filters, setFilters] = useState(lsFilters ? lsFilters : { ...initialFilterData });
  const { role = [], showImportant = false } = filters || {};
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const fetchUserActivity = useCallback(async (text) => {
    try {
      setLoading(true);
      const params = {
        search_text: text,
        page: currentPage,
        limit: pageSize,
        start_date: formatDateToDDMMYYYY(startDate) || undefined,
        end_date: formatDateToDDMMYYYY(endDate) || undefined,
        showImportant,
        role: role?.join(",") || undefined,
      };
      const { data } = await apiCall.get(USER_ACTIVITY, params);
      const count = Math.max(data?.totlatSearches || 0, data?.totlatLeads || 0);
      setTotalPages(Math.ceil(count / pageSize));
      setTotalCount(count);
      if (data) setUserActivity(data);
    } catch (error) {
      console.log("Error in fetching user activity", error.message);
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate, filters, searchText, currentPage, pageSize]);

  useEffect(() => {
    if (queryObject?.search_text) {
      setSearchText(queryObject?.search_text);
      handleFetchSearch(queryObject?.search_text);
    } else {
      setSearchText('');
      fetchUserActivity();
    }
  }, [currentPage, queryObject?.search_text, pageSize]);

  const handleFetchSearch = useCallback(
    (text) => {
      setCurrentPage(1);
      fetchUserActivity(text);
    },
    [fetchUserActivity]
  );

  const setAsImportant = useCallback(async () => {
    setLoading(true);
    const updateData = [
      {
        _id: updatedData?.data[0]?._id,
        type: "users",
        data: {
          important: updatedData?.data[0]?.important,
        },
      },
    ];
    await apiCall.patch(UPDATE_ENTITY_PATH, { updateData });
    fetchUserActivity(searchText);
  }, [updatedData, searchText]);

  const handleSetImportant = useCallback(async() => {
    const dataType =
      updatedData?.type === "leads"
        ? userActivity?.leads
        : userActivity?.searches;
    for (let i = 0; i < dataType.length; i++) {
      let activity = dataType[i];
      if (activity?.user_data?._id === updatedData?.data[0]?._id) {
        activity.user_data.important = !updatedData?.data?.important;
        await setAsImportant();
        break;
      }
    }
    setIsModalOpen(false);
  }, [userActivity, updatedData, searchText]);

  const toggleModal = (currentState, id, type) => {
    setIsModalOpen(!isModalOpen);
    setUpdatedData({
      data: [{ _id: id, important: !currentState, type: "users" }],
      type,
    });
  };

  const handleApplyFilter = () => {
    const updatedFilters = { ...filters, startDate, endDate };
    setFilterToLocalStorage(ENTITY_FILTER_TYPES.ACTIVITY_FILTER, updatedFilters);
    fetchUserActivity(searchText);
    setCurrentPage(1);
  };

  const handleFilterChange = useCallback((key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  }, []);

  const toggleSidebar = () => {
    setIsOpenFilterSidebar(!isOpenFilterSidebar);
  };

  const handleRemoveAllFilter = () => {
    setFilters(initialFilterData);
    setEndDate("");
    setStartDate("");
    setFilterToLocalStorage(ENTITY_FILTER_TYPES.ACTIVITY_FILTER, initialFilterData);
 
  };

  const dropdownFilters = [
    {
      name: "role",
      data: USER_ACTIVITY_ROLE_DATA,
      placeholder: "Select Role",
      value: role,
    },
  ];

  const checkBoxFilter = [
    {
      name: "showImportant",
      label: "Show Only Important",
      value: showImportant,
    },
  ];

  const allFiltersProps = {
    dropdowns: dropdownFilters,
    checkBox: checkBoxFilter,
  };

  const modalText = useMemo(() => {
    let heading = "Mark As Important!";
    let description = "Do You Want To Mark As Important ?"
    if(!updatedData?.data[0]?.important){
      heading = "Remove From Important!";
      description = "Do You Want To Remove From Important ?"
    }
    return {
      heading, description
    }
  }, [updatedData])

  const handleActionClick = (selectedRow, action) => {
    setOpenRequirementForm(true);
    setSelectedUser(selectedRow);
    let selectedRequirementData;
    if(selectedRow.filter_object){
      const filterData = JSON.parse(selectedRow.filter_object);
      selectedRequirementData = {
        property_type: PROPERTY_TYPE_OPTIONS[filterData?.property_type?.[0]?.property_type] ? filterData?.property_type?.[0]?.property_type : "",
        ad_type: filterData?.ad_type?.[0] || "",
        location: filterData?.location?.[0] || {},
        comment: formatSearchResult(selectedRow?.filter_object)
      };
    } else {
      selectedRequirementData = {
        property_type: PROPERTY_TYPE_OPTIONS[selectedRow?.ad_data?.property_type] ? selectedRow?.ad_data.property_type : "",
        ad_type: selectedRow?.ad_data?.ad_type || "",
        location: selectedRow?.ad_data?.location || "",
        comment: selectedRow?.ad_data?.slug_url || ""
      };
    }
    setRequirementData(selectedRequirementData);
  };

  const handleInputFormSubmit = (e) => {
    e.preventDefault();
    handleFetchSearch(searchText);
  };

  const handleAfterRequirementUpdate = () => {
    setOpenRequirementForm(false);
  };

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  return (
    <>
      <Box>
        <Box width={"90%"} display="flex" mb={4}>
          <SidebarFilter
            filters={allFiltersProps}
            handleFilterChange={handleFilterChange}
            handleApplyFilter={handleApplyFilter}
            isOpen={isOpenFilterSidebar}
            onClose={toggleSidebar}
            appliedFilters={filters}
            setFilters={setFilters}
            handleRemoveAllFilter={handleRemoveAllFilter}
            isCalenderFilter={true}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            enableMultiselect={true}
            multiSelectKey={["role"]}
          />
        </Box>
        <CommonModal
          onDone={handleSetImportant}
          heading={modalText?.heading}
          isOpen={isModalOpen}
          description={modalText?.description}
          onClose={() => setIsModalOpen(false)}
        />
        <Tabs variant="soft-rounded" colorScheme="green" ml={5} >
          <TabList display={"flex"} gap={5} mx={6}>
            <Button
              paddingX={6}
              leftIcon={<FiFilter />}
              rightIcon={<FiChevronsDown />}
              colorScheme="teal"
              variant="outline"
              borderRadius="md"
              size="sm"
              onClick={() => {
                toggleSidebar();
              }}
            >
              Filter
            </Button>
            <Tab>Searches ({userActivity?.totlatSearches})</Tab>
            <Tab>Leads ({userActivity?.totlatLeads})</Tab>

            <form onSubmit={handleInputFormSubmit}>
              <InputGroup size="sm">
                <SearchBar
                  background="#F9F9FB"
                  color="#637381"
                  setSearchText={(e) => setSearchText(e.target.value)}
                  searchText={searchText}
                  borderRadius="5px"
                  borderWidth="0.5px"
                  borderColor="#637381"
                />
              </InputGroup>
            </form>
            <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={(value) => setCurrentPage(value)}
                      totalCount={totalCount}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      setPageSizeToLocalStorage={(value) =>
                        setFilterToLocalStorage(
                          ENTITY_FILTER_TYPES.ACTIVITY_PAGE_SIZE,
                          value
                        )
                      }
            />
          </TabList>
          <TabPanels>
            <TabPanel>
              <TableContainer paddingEnd={24} paddingLeft={6}>
                <OverlayLoaderComponent isOpen={loading} />
                <UserActivityTable
                  columns={SearchesColumns}
                  data={userActivity?.searches}
                  onMakeImportant={(important, id) =>
                    toggleModal(important, id, "recent_searches")
                  }
                  onOptionClick={handleActionClick}
                  actions={actions}
                />
              </TableContainer>
            </TabPanel>
            <TabPanel>
              <TableContainer paddingEnd={24} paddingLeft={6} >
                <UserActivityTable
                  columns={LeadColumns}
                  data={userActivity?.leads}
                  onMakeImportant={(important, id) =>
                    toggleModal(important, id, "leads")
                  }
                  onOptionClick={handleActionClick}
                  actions={actions}
                />
              </TableContainer>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <UserRequirementModal
        isOpen={openRequirementForm}
        onClose={() => setOpenRequirementForm(false)}
        userId={selectedUser?.user_data?._id}
        selectedRequirement={requirementData}
        afterRequirementsUpdate={handleAfterRequirementUpdate}
      />
    </>
  );
}