import { CREATE_ADS_API } from "constants/endpoints";
import { showToast } from "../../../components/toast/Toast";
import { CREATE_PROJECTS } from "constants/endpoints";
import { ALL_PROPERTY_TYPE_TAGS } from "constants/dbEnums";
import { PROPERTY_COLLECTIONS } from "constants/dbEnums";
import { AD_TYPE_TAGS } from "constants/dbEnums";

export const createForm = async ({ apiCall, formData, propertyType }) => {
  const isProject = propertyType === ALL_PROPERTY_TYPE_TAGS.projects;
  try {
    const method = formData._id ? 'patch' : 'post';
    const url = isProject ? CREATE_PROJECTS : CREATE_ADS_API;
    const response = await apiCall[method](url, formData);
    if (response) {
      showToast({
        message: formData?._id ? "Form updated successfully." : "Form has been successfully submitted",
        success: true,
        customStyle: {
          background: "#52c41a",
          color: "#fff",
          padding: "10px",
        },
      });
    }
    return response;
  } catch (error) {
    console.error("Error creating/updating form:", error);
    showToast({
      message: error?.response?.data?.error?.message ? error?.response?.data?.error?.message  : error?.response?.data?.error,
      success: false,
      customStyle: {
        background: "#F24052",
        color: "#fff",
        padding: "10px",
      },
    });
    throw error?.response?.data?.error?.message ? error?.response?.data?.error?.message  : error?.response?.data?.error
  }
};

export const getStaticData = async ({ apiCall, formData, url }) => {
  try {
    const response = await apiCall.get(url, formData);
    return response;
  } catch (error) {
    console.error("Error fetching banks", error);
    showToast({
      message: error?.response?.data?.error,
      success: false,
      customStyle: {
        background: "#F24052",
        color: "#fff",
        padding: "10px",
      },
    });
    throw error;
  }
};

export const getFormData = async ({ apiCall, propertyId, collectionType, searchBy }) => {
  const isProject = collectionType === ALL_PROPERTY_TYPE_TAGS.projects;
  try {
    const url = `/admin/${collectionType}/${propertyId}${searchBy ? `?searchBy=${searchBy}` : ""}`;
    const response = await apiCall.get(url);
    return response?.data;
  } catch (error) {
    console.error("Error fetching form:", error);
    const errorMessage = isProject ? error?.response?.data?.error?.message : error?.response?.data?.error;
    if(errorMessage){
      showToast({
        message: errorMessage,
        success: false,
        customStyle: {
          background: "#F24052",
          color: "#fff",
          padding: "10px",
        },
      });
    }
    throw errorMessage ? errorMessage : error;
  }
};

export function removeKeyValueFromPayload(payload) {
  const {type, ad_type,property_type} = payload;
  let updatedPayload = payload;
  if(property_type==="1_rk_studio" && ad_type==="sell"){
    delete updatedPayload.bhk;
  }

  if(type === PROPERTY_COLLECTIONS.RESIDENTIAL_PROPERTY){
    const {is_on_lease} = updatedPayload;
    if(is_on_lease===false){
      delete updatedPayload.lease_years;
    }else{
      delete updatedPayload.yearly_appreciation;
    }
    if(ad_type === AD_TYPE_TAGS.SELL){
      const {preferred_tenant,is_on_lease, ...restPayload} = payload
      updatedPayload = restPayload;
      const {construction_status} = updatedPayload
      if(construction_status==="ready_to_move"){
        delete updatedPayload.possession_period;
      }else{
        delete updatedPayload.age_of_property;
      }
    };
    if(property_type==="vila/farm_house"){
      if(updatedPayload.your_floor){
         delete updatedPayload.your_floor;
      }
    }
  }
  if (type === PROPERTY_COLLECTIONS.COMMERCIAL_PROPERTY) {
    const {is_on_lease,construction_status,property_type} = updatedPayload;
    if(is_on_lease===false){
      delete updatedPayload.lease_years;
    }else{
      delete updatedPayload.yearly_appreciation;
    }
    if(construction_status==="ready_to_move"){
      delete updatedPayload.possession_period;
    }else{
      delete updatedPayload.age_of_property;
    }
    if(property_type==="office"){
      const{property_condition}= updatedPayload;
      if(is_on_lease===false){
        delete updatedPayload.lease_years;
      }else{
        delete updatedPayload.yearly_appreciation;
      }
      if(property_condition==="ready_to_use"){
        delete updatedPayload.construction_status_of_wall;
      }
      if(property_condition==="bare_shell"){
        delete updatedPayload.total_cabins;
        delete updatedPayload.total_seats;
      }
    }
  }

  if(property_type==="need_flat"){
    const {occupation}  = payload;
    if(!payload.furnish_type){
      delete updatedPayload.furnish_type;
      delete updatedPayload.furnish_type_items;
    }
    if(occupation==="student"){
      delete updatedPayload.designation;
    }
    if(occupation==="working" || occupation==="other"){
      delete updatedPayload.studying;
    }
  }

  if(property_type==="need_flatmate"){
    const {occupation}  = payload;
    delete updatedPayload.min_approx_price;
    if(occupation==="student"){
      delete updatedPayload.designation;
    }
    if(occupation==="working" || occupation==="other"){
      delete updatedPayload.studying;
    }
  }

  return updatedPayload;
}