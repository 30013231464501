import { Flex, Text, Tooltip} from "@chakra-ui/react";
import StatusBadge from "components/badge/StatusBadge";

export const userRequestColumns = [
  {
    Header: 'Name',
    accessor: 'user_data',
    Cell: ({ value }) => (
      <span>
        {value ? `${value.first_name || ''} ${value.last_name || ''}`.trim() : ''}
      </span>
    ),
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'Source',
    accessor: 'source',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'Note',
    accessor: 'note',
    Cell: ({ value }) => (
      <Tooltip label={value} hasArrow placement="top">
        <span>{value?.length > 15 ? `${value.substring(0, 15)}...` : value}</span>
      </Tooltip>
    ),
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <Flex align="center" justify={'center'}>
        <Text>
          <StatusBadge status={value} />
        </Text>
      </Flex>
    ),
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'Phone',
    accessor: 'user_data.phone',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'Email',
    accessor: 'user_data.email',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'Role',
    accessor: 'user_data.role',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'User ID',
    accessor: 'user_id',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    Header: 'Property Type',
    accessor: 'property_type',
    Cell: ({ value }) => {
      const propertyTypes = Array.isArray(value) ? value : [value];
  
      if (propertyTypes.length === 0) {
        return <span>-</span>;
      }
  
      const formattedPropertyTypes = propertyTypes.join(', ');
  
      return (
        <Tooltip label={formattedPropertyTypes} hasArrow placement="top">
          <span>
            {formattedPropertyTypes.length > 15 ? `${formattedPropertyTypes.substring(0, 15)} ....` : formattedPropertyTypes}
          </span>
        </Tooltip>
      );
    },
  },  
  {
    Header: 'Location',
    accessor: 'location',
    Cell: ({ value }) => {
      if (!value || value.length === 0) {
        return <span>-</span>;
      }
  
      const formatLocation = (loc) => {
        const parts = [];
        if (loc.city) parts.push(`City: ${loc.city}`);
        if (loc.locality && loc.locality !== '-') parts.push(`Locality: ${loc.locality}`);
        if (loc.sub_locality && loc.sub_locality !== '-') parts.push(`Sub Locality: ${loc.sub_locality}`);
        return parts.join(', ');
      };
  
      const locationElements = value.map((loc, index) => (
        <div key={index}>
          {formatLocation(loc)}
        </div>
      ));
  
      const firstLocation = formatLocation(value[0]);
      const additionalLocationsCount = value.length - 1;
  
      return (
        <Tooltip label={locationElements} hasArrow placement="top">
          <span>
            {firstLocation.length > 20 ? `${firstLocation.substring(0, 20)}...` : firstLocation}
            {additionalLocationsCount > 0 && ` (+${additionalLocationsCount} more)`}
          </span>
        </Tooltip>
      );
    },
  }
  
  
 
];
