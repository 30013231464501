import { StarIcon } from "@chakra-ui/icons";
import { Flex, Link, Text } from "@chakra-ui/react";
import { formatTime } from "constants/commonFunctions";
import { formatDate } from "constants/commonFunctions";
import { USER_ROLES } from "constants/dbEnums";
import { LEAD_ACTION_TAG } from "constants/dbEnums";
import { truncateString } from "utils/common";
import { roleBadgeColor } from "utils/common";

const LeadColumns = [
    {
        label: "Date",
        render: (data) =>
            `${formatDate(data?.created_at)}, ${formatTime(data?.created_at)}`,
    },
    {
        label: "Lead By",
        render: (data, onMakeImportant) => (
            <Flex justifyContent={"space-between"} width={"170px"}>
                <Link href={`/#/admin/users/?search_text=${data?.user_data?.phone}`}>
                    {data?.user_data?.phone ? truncateString(`${data?.user_data?.first_name} ${data?.user_data?.last_name}`) : "-"}
                </Link>
                <Flex alignItems={"center"}>
                    {
                        data?.user_data?.role && <Text fontWeight={"medium"} color={`${roleBadgeColor(data?.user_data?.role)}`} py={1}>{USER_ROLES[data?.user_data?.role]}</Text>
                    }
                    {
                        data?.user_data?.phone && <StarIcon onClick={() => onMakeImportant(data?.user_data?.important, data?.user_data?._id)} w={4} h={4} cursor={"pointer"} color={data?.user_data?.important ? "orange" : "gray"} marginLeft={1} />
                    }
                </Flex>
            </Flex>
        ),
    },
    {
        label: "Lead Action",
        render: (data) => `${LEAD_ACTION_TAG[data?.lead_action]}`,
    },
    {
        label: "Phone", render: (data) => (
            <Link href={`/#/admin/users/?search_text=${data?.user_data?.phone}`}>
                {data?.user_data?.phone || data?.user_session_id}
            </Link>
        )
    }
    ,
    {
        label: "Owner",
        render: (data) => (
            <Link href={`/#/admin/users/?search_text=${data?.owner_data?.phone}`}>
                {data?.owner_data ? `${data?.owner_data?.first_name} ${data?.owner_data?.last_name} (${USER_ROLES[data?.owner_data?.role]})` : "-"}
            </Link>
        ),
    },
    {
        label: "Property",
        render: (data) => (
            <Link
                target="_blank"
                href={`${process.env.REACT_APP_HEXAHOME_BASE_URL}/${data?.ad_data?.slug_url}`}
            >
                {`${data?.ad_data?.slug_url || "-"}`}
            </Link>
        ),
    }
];

export default LeadColumns;